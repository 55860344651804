<template>
  <div>
    <vx-card class="mb-4" :title="userName()">
      <template slot="actions">
        <vx-tooltip text="Retour" color="primary">
          <router-link
            class="ml-2 text-primary"
            :to="{ name: `${this.crrModuleName}` }"
          >
            <feather-icon
              icon="CornerUpLeftIcon"
              svgClasses="w-10 h-10"
            ></feather-icon>
          </router-link>
        </vx-tooltip>
      </template>
      <div slot="no-body">
        <div class="aab-vie-content">
          <div>
            <vs-prompt
              title="Exportation"
              class="export-options"
              @cancel="clearFields"
              @accept="exportToData"
              accept-text="Valider"
              cancel-text="Annuler"
              @close="clearFields"
              :active.sync="activePrompt"
            >
              <vs-input
                v-model="fileName"
                placeholder="Entrez le nom du fichier..."
                class="w-full"
              />
              <multiselect
                v-model="selectedFormat"
                :options="formats"
                class="my-4"
                placeholder="--Choisir--"
                :selectLabel="global.selectLabel"
                :selectedLabel="global.selectedLabel"
                :deselectLabel="global.deselectLabel"
              >
                <template v-slot:noOptions>
                  <span>{{ global.selectNotFoundData }}</span>
                </template>
              </multiselect>

              <div class="flex">
                <span class="mr-4">Largeur automatique de cellule:</span>
                <vs-switch v-model="cellAutoWidth"
                  >Largeur automatique de cellule</vs-switch
                >
              </div>
            </vs-prompt>

            <div class="clearfix mb-3 mt-6">
              <div
                class="flex flex-wrap-reverse items-center data-list-btn-container"
              >
                <div>
                  <vs-button
                    v-if="checkUserRole('mail_received').create"
                    class="mr-1"
                    @click="popupAddEmail = true"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    >Ajouter</vs-button
                  >
                  <vx-tooltip
                    color="primary"
                    :title="role.notAllowTitle"
                    :text="role.notAllowText"
                    v-else
                  >
                    <vs-button
                      :disabled="true"
                      color="primary"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                      >Ajouter</vs-button
                    >
                  </vx-tooltip>
                </div>
                <div>
                  <vs-dropdown
                    
                    class="dd-actions cursor-pointer ml-4"
                  >
                    <div
                      class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
                    >
                      <span class="mr-2">Actions</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="activePrompt = true">
                        <span class="flex items-center">
                          <feather-icon
                            icon="PrinterIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Exporter</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
                <div>
                  <div>
                    <vs-dropdown
                      
                      class="dd-actions cursor-pointer ml-4"
                    >
                      <div
                        class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
                      >
                        <span class="mr-2">Afficher {{ limit }}</span>
                        <feather-icon
                          icon="ChevronDownIcon"
                          svgClasses="h-4 w-4"
                        />
                      </div>
                      <vs-dropdown-menu>
                        <vs-dropdown-item
                          :key="i"
                          v-for="(r, i) in rowsTable"
                          @click="limit = r"
                        >
                          <span class="flex items-center">
                            <span>{{ r }}</span>
                          </span>
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </div>
                </div>
              </div>
              <div class>
                <div class="w-auto" v-if="loading">
                  <clip-loader :loading="loading" color="primary"></clip-loader>
                </div>
                <div v-else>
                  <div v-if="checkUserRole('mail_received').all">
                    <div v-if="allMailSent.list.length !== 0">
                      <vs-table
                        multiple
                        @dblSelection="mailSelectedDouble"
                        v-model="mailSelected"
                        :data="allMailSent.list"
                        search
                        stripe
                        pagination
                        :max-items="limit != 'Tout' ? limit : total"
                      >
                        <template slot="thead">
                          <vs-th>N°</vs-th>
                          <vs-th sort-key="name">Date d'envoi </vs-th>
                          <vs-th sort-key="contratType">Objet</vs-th>
                          <vs-th>Actions</vs-th>
                        </template>
                        <template slot-scope="{ data }">
                          <vs-tr
                            :data="tr"
                            :key="indextr"
                            v-for="(tr, indextr) in data"
                          >
                            <vs-td :data="data[indextr].id">{{
                              indextr + 1
                            }}</vs-td>
                            <vs-td :data="data[indextr].createdAt">{{
                              dateElement(data[indextr]).textCreatedAt
                            }}</vs-td>
                            <vs-td :data="data[indextr].object">{{
                              data[indextr].object
                            }}</vs-td>

                            <vs-td :data="data[indextr].id">
                              <div class="inline-flex" @click.stop>
                                <div>
                                  <vx-tooltip
                                    v-if="checkUserRole('mail_received').show"
                                    color="primary"
                                    text="Visualiser"
                                  >
                                    <div
                                      class="ml-8 cursor-pointer"
                                      @click="checkViewMail(data[indextr])"
                                    >
                                      <feather-icon
                                        icon="EyeIcon"
                                        svgClasses="w-6 h-6"
                                      ></feather-icon>
                                    </div>
                                  </vx-tooltip>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <div class="ml-8 cursor-pointer">
                                      <feather-icon
                                        icon="EyeIcon"
                                        svgClasses="w-6 h-6"
                                      ></feather-icon>
                                    </div>
                                  </vx-tooltip>
                                </div>

                                <div>
                                  <vx-tooltip
                                    v-if="checkUserRole('mail_received').show"
                                    color="primary"
                                    text="Supprimer"
                                  >
                                    <div
                                    class="ml-8 cursor-pointer"
                                    @click="checkRemoveMail(data[indextr])"
                                  >
                                    <feather-icon
                                      icon="Trash2Icon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                  </vx-tooltip>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <div
                                    class="ml-8 cursor-pointer"
                                  >
                                    <feather-icon
                                      icon="Trash2Icon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                  </vx-tooltip>
                                </div>

                                <vx-tooltip
                                  color="primary"
                                  title="Information"
                                  :text="aboutElement(data[indextr]).text"
                                >
                                  <div class="ml-8 cursor-pointer">
                                    <feather-icon
                                      icon="InfoIcon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>

                                <div
                                class="ml-2"
                                v-show="woIsAction == data[indextr].id"
                              >
                                <clip-loader
                                  :loading="
                                    loadingRemoveMail "
                                  color="primary"
                                  size="25px"
                                ></clip-loader>
                              </div>
                              </div>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                      <vs-pagination
                        class="mt-6"
                        :total="allPages"
                        v-model="currentPage"
                      ></vs-pagination>
                    </div>
                    <div v-else>
                      <div class="flex items-center justify-center mt-8">
                        <strong>Aucun enregistrement</strong>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p>{{ role.notAllowText }}</p>
                  </div>
                </div>
              </div>

              <vs-popup
                class="holamundo"
                title="Envoyer mail"
                :active.sync="popupAddEmail"
              >
                <div class="m-4">
                  <div class="clearfix">
                    <div>
                      <vs-row vs-w="12">
                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full mb-2 mt-2"
                              label="A*"
                              v-model="form.a"
                              disabled
                              v-validate="'required'"
                              data-vv-validate-on="blur"
                              name="A"
                            />
                            <span class="text-danger text-sm">{{
                              errors.first("A")
                            }}</span>
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full mb-2 mt-2"
                              label="CC "
                              v-model="form.cc"
                            />
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full mb-2 mt-2"
                              label="CCI "
                              v-model="form.cci"
                            />
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full mb-2 mt-2"
                              label="Objet*"
                              v-model="form.object"
                              v-validate="'required'"
                              data-vv-validate-on="blur"
                              name="Objet"
                            />
                            <span class="text-danger text-sm">{{
                              errors.first("Objet")
                            }}</span>
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                          style="flex-direction: column;"
                        >
                          <div
                            class="flex mt-2 mb-3"
                            style="flex-direction: column;"
                          >
                            <p class="pl-2">Message*</p>
                          </div>
                          <div class="w-full">
                            <quill-editor
                              :option="editorOption"
                              v-model="form.message"
                            ></quill-editor>
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-4 mb-2 mt-8"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div
                            class="w-full"
                            style="display: flex;
                            align-items: flex-end;
                            justify-content: space-between;"
                          >
                            <div>
                              <div>
                                <span class=" ml-2">Fichier(s)</span>
                                <div>
                                  <div uk-form-custom class="ebsi-upload-file">
                                    <input
                                      multiple
                                      type="file"
                                      :value="resetFilesUpload"
                                      v-on:change="checkUploadFile"
                                    />
                                    <button
                                      style="cursor:pointer"
                                      class="uk-button uk-button-default"
                                      type="button"
                                      tabindex="-1"
                                    >
                                    Choisir vos fichiers
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <span
                                class="text-danger text-sm"
                                v-show="formError"
                                >Erreur lors du chargement du fichier</span
                              >
                            </div>
                          </div>
                          <div
                            class=" mt-3 mb-3"
                            style="
                          display: flex;
                          flex-direction: column;"
                          >
                            <div class="mb-4" v-if="form.filesname.length > 0">
                              <p><b>Liste des fichiers</b></p>
                            </div>
                            <div
                              v-for="(file, index) in form.filesname"
                              :key="file.name"
                              class="center"
                            >
                              <div v-if="index == 0" class="flex">
                                {{ index + 1 }})
                                <b class="pl-2">{{ subString(file) }}</b>
                                <vx-tooltip color="primary" text="Supprimer">
                                  <div
                                    class="ml-8 cursor-pointer"
                                    @click="removeFile(index, 'Add')"
                                  >
                                    <feather-icon
                                      icon="Trash2Icon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>
                              </div>
                              <div v-else>
                                <div class="flex">
                                  {{ index + 1 }})
                                  <b class="pl-2">{{ subString(file) }}</b>
                                  <vx-tooltip color="primary" text="Supprimer">
                                    <div
                                      class="ml-8 cursor-pointer"
                                      @click="removeFile(index, 'Add')"
                                    >
                                      <feather-icon
                                        icon="Trash2Icon"
                                        svgClasses="w-6 h-6"
                                      ></feather-icon>
                                    </div>
                                  </vx-tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </vs-col>

                        <vs-col
                          v-if="displayFileName"
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div
                            class="inputx w-full flex justify-around"
                            style="align-items: center;"
                          >
                            <span>
                              {{ form.fileName }}
                            </span>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                  </div>
                  <div class="mt-8">
                    <div class="inline-flex">
                      <div>
                        <vs-button
                          class="w-full sm:w-auto"
                          :disabled="
                            !validateFormAddMailSent || loadingAddMailSent
                          "
                          @click="addMailSent"
                          >Valider</vs-button
                        >
                        <vs-button
                          class="w-full sm:w-auto ml-4"
                          type="border"
                          @click="popupAddEmail = false"
                          >Annuler</vs-button
                        >
                      </div>
                      <div class="ml-2">
                        <clip-loader
                          :loading="loadingAddMailSent"
                          color="primary"
                        >
                        </clip-loader>
                      </div>
                    </div>
                    <div class="mt-6">
                      <p class="text-danger">* Champ obligatoire</p>
                    </div>
                  </div>
                </div>
              </vs-popup>

              <vs-popup
                class="holamundo"
                title="Visualisation"
                :active.sync="popupViewEmail"
              >
                <div class="m-4">
                  <div class="clearfix">
                    <div>
                      <vs-row vs-w="12">
                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full mb-2 mt-2"
                              label="A :"
                              v-model="formView.a"
                              disabled
                              v-validate="'required'"
                              data-vv-validate-on="blur"
                              name="A"
                            />
                            <span class="text-danger text-sm">{{
                              errors.first("A")
                            }}</span>
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full mb-2 mt-2"
                              label="CC :"
                              v-model="formView.cc"
                              disabled
                              v-validate="'required'"
                              data-vv-validate-on="blur"
                              name="CC"
                            />
                            <span class="text-danger text-sm">{{
                              errors.first("CC")
                            }}</span>
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full mb-2 mt-2"
                              label="CCI :"
                              v-model="formView.cci"
                              disabled
                              v-validate="'required'"
                              data-vv-validate-on="blur"
                              name="CCI"
                            />
                            <span class="text-danger text-sm">{{
                              errors.first("CCI")
                            }}</span>
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full mb-2 mt-2"
                              label="Objet :"
                              v-model="formView.object"
                              v-validate="'required'"
                              disabled
                              data-vv-validate-on="blur"
                              name="Objet"
                            />
                            <span class="text-danger text-sm">{{
                              errors.first("Objet")
                            }}</span>
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                          style="flex-direction: column;"
                        >
                          <div
                            class="flex mt-2 mb-3"
                            style="flex-direction: column;"
                          >
                            <p class="pl-2">Message :</p>
                          </div>
                          <div class="w-full">
                            <p class="pl-4">
                              <b><span v-html="formView.message"></span></b>
                            </p>
                          </div>
                        </vs-col>

                        <vs-col
                          class="pr-4 mb-2 mt-8"
                          vs-lg="12"
                          vs-sm="12"
                          vs-xs="12"
                        >
                          <div
                            class=" mt-3 mb-3"
                            style="
                          display: flex;
                          flex-direction: column;"
                          >
                            <div
                              v-for="(file, index) in formView.filesname"
                              :key="file.name"
                              class="center"
                            >
                              <div class="flex" style="cursor:pointer">
                                {{ index + 1 }})
                                <b class="pl-2" @click="checkViewFile(file)">{{
                                  subString(file.name)
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                  </div>
                  <div class="mt-8">
                    <div class="inline-flex">
                      <div>
                        <vs-button
                          class="w-full sm:w-auto ml-4"
                          type="border"
                          @click="popupViewEmail = false"
                          >Annuler</vs-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </vs-popup>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import moment from "@/helpers/moment";
import role from "@/config/role";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  name: "aab-vie-mail-sent",
  components: {
    ClipLoader,
    quillEditor,
    Multiselect
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            [
              "bold",
              "italic",
              "underline",
              "strike",
              "link",
              "blockquote",
              "code-block"
            ],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ font: [] }]
          ]
        },
        placeholder: "Message"
      },

      form: {
        a: "",
        cc: "",
        cci: "",
        object: "",
        message: "",
        filesname: [],
        whoReceive: "",
        whoSend: ""
      },
      resetFilesUpload: "",
      formView: {
        a: "",
        cc: "",
        cci: "",
        object: "",
        message: "",
        filesname: [],
        whoReceive: "",
        whoSend: ""
      },
      role: {},
      global: {},
      loading: false,
      loadingRemoveMail: false,
      loadingAddMailSent: false,

      userData: {},
      crrModuleName: "",
      filesList: [],
      popupAddEmail: false,
      popupViewEmail: false,
      displayFileName: false,
      formError: false,
      mailSelected: [],
      woIsAction: "",
      crrIDs: [],
      crrID: "",
      formData: [],
      fileDataUp: [],
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"],//formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["N°", "Date d'envoi", "Objet"],
      headerVal: ["index", "createdAt", "object"],
      activePrompt: false
      //end export section
    };
  },
  computed: {
    ...mapGetters("general", ["rowsTable"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("emailSent", ["allMailSent"]),

    validateFormAddMailSent() {
      return (
        !this.errors.any() &&
        this.form.a != "" &&
        this.form.object != "" &&
        this.form.message != ""
      );
    }
  },
  mounted() {
    this.getUserData(this.$route.params.userId);
    this.crrModuleName = this.$route.params.crrModuleName;
    this.limit = this.rowsTable[1];
  },

  methods: {
    ...mapActions("emailSent", ["applyGetAllMailSent", "applyAddMailSent", "applyRemoveMail"]),

    ...mapActions("users", ["applyGetUserSys"]),

    ...mapActions("general", ["applyUploadFile"]),

    checkViewMail(item) {
      this.formView.filesname = [];

      this.crrID = item.id;

      this.formView = { ...item };

      this.popupViewEmail = true;
    },
    clearFields() {
      (this.filesname = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    userName() {
      if (this.userData.person) {
        return (
          this.userData.person.firstname + "  " + this.userData.person.lastname
        );
      } else {
        return "";
      }
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    checkRemoveMail(mail) {
      this.crrID = mail.id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer ce mail ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveMail
      });
    },
        async fetchApplyRemoveMail() {
      this.woIsAction = this.crrID;
      let data = {
        ids: [this.crrID]
      };
      this.loadingRemoveMail = true;
      try {
        await this.applyRemoveMail(data);
        this.loadingRemoveMail = false;
        this.acceptAlert(mixin.methods.message("mailing", "delete").success);
        this.woIsAction = "";
      } catch (err) {
        this.loadingRemoveMail = false;
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expiré"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {


                default:
                  this.acceptAlert(
                    mixin.methods.message("mailing", "delete").error
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async getUserData(userId) {
      this.loading = true;
      try {
        let user = await this.applyGetUserSys({ userId: userId });
        this.userData = user.data;
        this.form.a = this.userData.email;

        this.fetchAllMailSent({
          limit: this.limit,
          skip: this.skip,
          whoReceive: this.userData.id
        });
        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },

    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },

    checkUserRole(pack) {
      return role[this.user.type][pack];
    },

    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },

    subString(data) {
      if (data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },

    removeFile(data, option) {
      if (option == "Add") {
        console.log("this.formData", this.formData)
        console.log("this.form.filesname", this.form.filesname)
        this.formData.splice(data, 1);
        this.form.filesname.splice(data, 1);
        console.log("this.formData", this.formData)
        console.log("this.form.filesname", this.form.filesname)
      } else {
        this.formDataUpdated.splice(data, 1);
        this.formUpdated.filesname.splice(data, 1);
      }
    },

    checkViewFile(item) {
      console.log("item", item);
      window.open(this.showFileData(item.filename), "_blank");
    },

    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    },

    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];
        this.allMailSent.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            createdAt: this.dateElement(el).textCreatedAt,
            object: el.object
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "MAILS RECU"
        );
      } else {
        let tableToPrint = [];
        this.allMailSent.list.forEach(el => {
          let jsonTmp = {
            createdAt: this.dateElement(el).textCreatedAt,
            object: el.object,
            a: el.a,
            cc: el.cc,
            cci: el.cci,
            whoReceive: el.whoReceive.email
          };
          tableToPrint.push(jsonTmp);
        });
        let headerValTmp = [
          "createdAt",
          "object",
          "a",
          "cc",
          "cci",
          "whoReceive"
        ];

        let headerTitleTmp = [
          "Date d' envoi",
          "Objet",
          " A",
          " CC",
          "CCI",
          "Envoyé par"
        ];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },

    checkUploadFile(evt) {
      console.log("evt", evt);
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formData.length;

      if (evtLength == 0) {
        for (let i = 0; i < evtLength; i++) {
          let formDataTmp = new FormData();
          formDataTmp.append("uri", evt.target.files[i]);
          this.formData[formDataLength] = formDataTmp;
          this.formError = false;
          allFilesChecked.push(evt.target.files[i].name);
          formDataLength++;
        }
      } else {
        for (let i = 0; i < evtLength; i++) {
          let formDataTmp = new FormData();
          formDataTmp.append("uri", evt.target.files[i]);
          this.formData[formDataLength + 1] = formDataTmp;
          this.formError = false;
          allFilesChecked.push(evt.target.files[i].name);
          formDataLength++;
        }
      }

      console.log("list fichier", allFilesChecked);
      this.form.filesname = [...this.form.filesname, ...allFilesChecked];
      this.displayFileName = true;
    },

    async uploadedFiles(option) {
      let _this = this;
      if (option == "Add") {
        let productPromises = [];
        this.formData.forEach(function(ele, index) {
          productPromises.push(
            (async function(ele) {
              const res = await _this.applyUploadFile(ele);
              _this.fileDataUp.push({
                filename: res.data[0].uploadedName,
                name: res.data[0].filename
              });
            })(ele)
          );
        });
        await Promise.all(productPromises);
      } else {
        let productPromises = [];
        this.formDataUpdated.forEach(function(ele, index) {
          productPromises.push(
            (async function(ele) {
              const res = await _this.applyUploadFile(ele);
              _this.fileDataUpUpdated.push({
                filename: res.data[0].uploadedName,
                name: res.data[0].filename
              });
            })(ele)
          );
        });
        await Promise.all(productPromises);
      }
    },

    async fetchApplyAddMailSent() {
      this.loadingAddMailSent = true;

      try {
        let data = {
          ...this.form,
          filesname: [...this.fileDataUp],
          whoReceive: this.userData.id,
          whoSend: this.user.id
        };

        console.log("dataa", data);
        await this.applyAddMailSent(data).then(() => {
          this.loadingAddMailSent = false;
          this.popupAddEmail = false;

          this.acceptAlert(mixin.methods.message("mails", "add").success);
          this.form = {
            a: "",
            cc: "",
            cci: "",
            object: "",
            message: "",
            filesname: [],
            whoReceive: "",
            whoSend: ""
          };

          this.form.a = this.userData.email;

          this.fileDataUp = [];
          this.formData = [];
        });
      } catch (err) {
        console.log(err);
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupAddEmail = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "mailNotFound":
                  this.errorMsg = "MaiL inexistant";
                  this.acceptAlert("danger", "MAIL", "MaiL inexistant");
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("mails", "add").error
                  );

                  break;
              }
            });
          }
        }
      }
    },

    async addMailSent() {
      this.loadingAddMailSent = true;

      if (this.form.filesname.length > 0) {
        await this.uploadedFiles("Add")
          .then(res => {
            console.log(res);
            this.acceptAlert(mixin.methods.message("mails", "file").success);
            setTimeout(async () => {
              this.fetchApplyAddMailSent();
            }, 1000);
          })
          .catch(() => {
            this.acceptAlert(
              "danger",
              "Téléchargement",
              "Un problème est survenu lors de la récupération des fichiers"
            );
            this.fetchApplyAddMailSent();
          });
      } else {
        this.fetchApplyAddMailSent();
      }
    },

    async fetchAllMailSent(data) {
      this.loading = true;

      console.log(data);

      try {
        await this.applyGetAllMailSent(data);
        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    checkUpdateProduit(produit) {
      this.formUpdated.filesname = [];
      this.formUpdated = {
        ...produit
      };
      this.crrID = produit.id;
      this.popupViewEmail = true;
    },

    mailSelectedDouble(item) {
      if (this.checkUserRole("mail_received").show) {
        this.checkViewMail(item);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    }
  },
  watch: {
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;
      if (this.userData.id) {
        this.fetchAllMailSent({
          limit: this.limit,
          skip: this.skip,
          whoReceive: this.userData.id
        });
      }
    },

    limit() {
      this.skip = 0;
      if (this.userData.id) {
        this.fetchAllMailSent({
          limit: this.limit,
          skip: this.skip,
          whoReceive: this.userData.id
        });
      }
    }
  },

  created() {
    this.role = role;
    this.global = global;
    Validator.localize("fr", VeeValidateFR);
  }
};
</script>

<style scoped lang="scss">
.ql-editor {
  height: 100px !important;
}
</style>
